<template>
<div>
  <div class="loader-container" v-if="loading">
    <span class="loader"></span>
  </div>
  <div class="sort-section">
    <label for="sortDropdown">Sortiraj po marki:</label>
    <select id="sortDropdown" v-model="selectedSort">
      <option value="all" selected>Prikaži sve</option>
      <option value="hyundai">Hyundai</option>
      <option value="fujitsu">Fujitsu</option>
      <option value="toshiba">Toshiba</option>
      <option value="mitsubishi">Mitsubishi</option>
      <option value="aux">Aux</option>
      <option value="qzen">QZEN</option>
    </select>
  </div>
  <div v-if="(!loading && !items.length) || noItemsToShow">
    <img class="noitemimage" src="../../src/assets/images/noitems.png" alt="Your Image">
    <div class="noitemimage">
        Trenutno nema ni jedan uređaj.
    </div>
  </div>
  <div class="box-container">
    <div class="wtf-box" v-for="item in displayedItems" :key="item">
      <div v-if="item.marka === selectedSort || selectedSort === 'all'" class="box">
        <img :src="getImageSrc(item.filename)" alt="Item Image" />
        <div class="content">
          <h2>{{item.text}}</h2>
          <p class="number">{{item.price}} €</p>
          <ul class="bullet-list">
            <p v-if="item.desc1">{{item.desc1}}</p>
            <p v-else>&nbsp;</p>
            <p v-if="item.desc2">{{item.desc2}}</p>
            <p v-else>&nbsp;</p>
            <p v-if="item.desc3">{{item.desc3}}</p>
            <p v-else>&nbsp;</p>
            <p v-if="item.desc4">{{item.desc4}}</p>
            <p v-else>&nbsp;</p>
            <p v-if="item.desc5">{{item.desc5}}</p>
            <p v-else>&nbsp;</p>
            <p v-if="item.desc6">{{item.desc6}}</p>
            <p v-else>&nbsp;</p>
          </ul>
          <!-- <div class="button" @click="redirectToContact(item)">Pošalji upit!</div> -->
          <div class="button" @click="openDetailsModal(item)">Pokaži više informacija.</div>
        </div>
      </div>
    </div>
  </div>
    <div v-if="displayedItems.length < filteredItems.length">
      <div class="button-add-more" @click="showMoreItems">Prikaži još</div>
    </div>
  <DetailsModal v-if="showDetailsModal" :item="selectedItem" @closeModal="closeDetailsModal" @redirect="redirectToContact"></DetailsModal>
</div>
</template>


<script>
import DetailsModal from './DetailsModal.vue'

export default
{
  name: 'ItemsPage',
  components: {
    DetailsModal
  },
  props: ['selectedKlima'],
  data()
  {
    return {
      items: [{
  "text": "Hyundai Performance Inverter Plus 2.6 kW - HRH-09BMV/HRO-09BMV",
  "desc1": "Učin hlađenja (kW): 2,6",
  "desc2": "Učin grijanja (kW): 2,9",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Osnovni model Hyundai klima uređaja hladi vrući zrak u prostoriji na željenu temperaturu i pritom odlično izgleda. Klima uređaj Hyundai Performance Inverter Plus HRH-09BMV/HRO-09BMV u prostorijama do 25 m² osigurava ugodnu klimu. Istovremeno filtrira zrak u prostoriji pomoću filtarskog sustava koji se sastoji od filtera visoke gustoće, aromatizirajućeg filtera i filtera s aktivnim ugljenom. Dodatnu kvalitetu zraka ovom modelu Hyundai klima uređaja osigurava i ionizator zraka.",
  "price": "486,76",
  "marka": "hyundai",
  "filename": "1700072818452-1.png"
},{
  "text": "Hyundai Performance Inverter Plus 3.5 kW",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 3,8",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Osnovni model Hyundai klima uređaja hladi vrući zrak u prostoriji na željenu temperaturu i pritom odlično izgleda. Klima uređaj Hyundai Performance Inverter Plus HRH-12BMV/HRO-12BMV u prostorijama do 35 m² osigurava ugodnu klimu. Istovremeno filtrira zrak u prostoriji pomoću filtarskog sustava koji se sastoji od filtera visoke gustoće, aromatizirajućeg filtera i filtera s aktivnim ugljenom. Dodatnu kvalitetu zraka ovom modelu Hyundai klima uređaja osigurava i ionizator zraka. Također, ova serija koristi ekološki prihvatljiv rashladni medij R32 koji ne samo da pomaže pri očuvanju okoliša, već i pridonosi energetskoj učinkovitosti uređaja. ",
  "price": "595,60",
  "marka": "hyundai",
  "filename": "1700072960483-3.png"
},{
  "text": "Hyundai Performance Inverter Plus 5.3 kW",
  "desc1": "Učin hlađenja (kW): 5,3",
  "desc2": "Učin grijanja (kW): 5,6",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Osnovni model Hyundai klima uređaja hladi vrući zrak u prostoriji na željenu temperaturu i pritom odlično izgleda. Klima uređaj Hyundai Performance Inverter Plus HRH-18BMV/HRO-18BMV u prostorijama do 55 m² osigurava ugodnu klimu. Istovremeno filtrira zrak u prostoriji pomoću filtarskog sustava koji se sastoji od filtera visoke gustoće, aromatizirajućeg filtera i filtera s aktivnim ugljenom. Dodatnu kvalitetu zraka ovom modelu Hyundai klima uređaja osigurava i ionizator zraka. Također, ova serija koristi ekološki prihvatljiv rashladni medij R32 koji ne samo da pomaže pri očuvanju okoliša, već i pridonosi energetskoj učinkovitosti uređaja. ",
  "price": "1.051,84",
  "marka": "hyundai",
  "filename": "1700073033006-4.png"
},{
  "text": "Hyundai Performance Inverter Plus 7.0 kW - HRH-24BMV/HRO-24BMV",
  "desc1": "Učin hlađenja (kW): 7,0",
  "desc2": "Učin grijanja (kW): 7,3",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 66 - 75",
  "desc5": "",
  "desc6": "",
  "desc7": "Osnovni model Hyundai klima uređaja hladi vrući zrak u prostoriji na željenu temperaturu i pritom odlično izgleda. Klima uređaj Hyundai Performance Inverter Plus HRH-24BMV/HRO-24BMV u prostorijama do 75 m² osigurava ugodnu klimu.",
  "price": "1.355,44",
  "marka": "hyundai",
  "filename": "1700073033006-4.png",
},{
  "text": "Hyundai Elite Inverter Plus 2.7 kW - HRH-09GMV3/HRO-09GMV3",
  "desc1": "Učin hlađenja (kW): 2,7",
  "desc2": "Učin grijanja (kW): 3,1",
  "desc3": "Energetski razred: A+++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Ovaj napredni model Hyundai klima uređaja s razlogom osvaja tržište. Uz svoj inovativni dizajn i moćne performanse Hyundai Elite Inverter Plus HRH-09GMV3/HRO-09GMV3 nudi komfornu raznolikost funkcija i tehniku hlađenja najnovije generacije. Ovaj iznimni klima uređaj pobrinut će se za sve, bilo to hlađenje, odvlaživanje ili grijanje.",
  "price": "685,00",
  "marka": "hyundai",
  "filename": "1700073033006-4.png",
},{
  "text": "Hyundai Elite Inverter Plus 3.5 kW - HRH-12GMV3/HRO-12GMV3",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 3,9",
  "desc3": "Energetski razred: A+++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Ovaj napredni model Hyundai klima uređaja s razlogom osvaja tržište. Uz svoj inovativni dizajn i moćne performanse Hyundai Elite Inverter Plus HRH-12GMV3/HRO-12GMV3 nudi komfornu raznolikost funkcija i tehniku hlađenja najnovije generacije. Ovaj iznimni klima uređaj pobrinut će se za sve, bilo to hlađenje, odvlaživanje ili grijanje.",
  "price": "730,00",
  "marka": "hyundai",
  "filename": "1700073033006-4.png",
},{
  "text": "Hyundai Elite Inverter Plus 5.3 kW - HRH-18GMV3/HRO-18GMV3",
  "desc1": "Učin hlađenja (kW): 5,3",
  "desc2": "Učin grijanja (kW): 5,6",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Ovaj napredni model Hyundai klima uređaja s razlogom osvaja tržište. Uz svoj inovativni dizajn i moćne performanse Hyundai Elite Inverter Plus HRH-18GMV3/HRO-18GMV3 nudi komfornu raznolikost funkcija i tehniku hlađenja najnovije generacije. Ovaj iznimni klima uređaj pobrinut će se za sve, bilo to hlađenje, odvlaživanje ili grijanje.",
  "price": "1.106,25",
  "marka": "hyundai",
  "filename": "1700073033006-4.png",
},{
  "text": "Hyundai Super Inverter 2.6 kW - HRH-09PMV1/HRO-09PMV1",
  "desc1": "Učin hlađenja (kW): 2,6",
  "desc2": "Učin grijanja (kW): 2,9",
  "desc3": "Energetski razred: A+++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Vrhunski model Hyundai klima uređaja koji oduševljava iznimnim karakteristikama. Uz tihi rad i malenu potrošnju električne energije, Hyundai Super Inverter HRH-09PMV1/HRO-09PMV1 predstavlja se i kao ozbiljan kandidat za grijanje u hladnim zimskim mjesecima. Naravno, ova serija koristi ekološki prihvatljiv rashladni medij R32 koji pomaže pri očuvanju okoliša.",
  "price": "666,30",
  "marka": "hyundai",
  "filename": "1700073033006-4.png",
},{
  "text": "AUX J-Smart 2.7 kW",
  "desc1": "Učin hlađenja (kW): 2,7",
  "desc2": "Učin grijanja (kW): 3,0",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Ukoliko tražite uređaj koji pouzdano i štedljivo hladi kada je to najpotrebnije, tada je AUX J-Smart pravi izbor za vas. S visokom energetskom učinkovitošću i A++ energetskim razredom predstavlja odličan odabir za štedljivo hlađenje. Na pouzdan rad možete računati tokom cijele godine, pa i za vrijeme posebno hladnih zimskih dana, jer AUX J-Smart dolazi s dodatnim grijačima kompresora i kadice u vanjskoj jedinici. Oni će se pobrinuti da kompresor ispravno radi i da se kondenzat u kadici ne smrzava. Na ovaj se način osigurava ispravno grijanje i dodatno smanjuje potrošnja energije.",
  "price": "407,91",
  "marka": "aux",
  "filename": "1700073230976-5.png"
},{
  "text": "AUX J-Smart 3.5 kW",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 3,8",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Ukoliko tražite uređaj koji pouzdano i štedljivo hladi kada je to najpotrebnije, tada je AUX J-Smart pravi izbor za vas. S visokom energetskom učinkovitošću i A++ energetskim razredom predstavlja odličan odabir za štedljivo hlađenje. Na pouzdan rad možete računati tokom cijele godine, pa i za vrijeme posebno hladnih zimskih dana, jer AUX J-Smart dolazi s dodatnim grijačima kompresora i kadice u vanjskoj jedinici. Oni će se pobrinuti da kompresor ispravno radi i da se kondenzat u kadici ne smrzava. Na ovaj se način osigurava ispravno grijanje i dodatno smanjuje potrošnja energije.",
  "price": "439,01",
  "marka": "aux",
  "filename": "1700073300009-6.png"
},{
  "text": "AUX J-Smart 5.3 kW - ARI-18JEH/ARO-18JEH",
  "desc1": "Učin hlađenja (kW): 5,3",
  "desc2": "Učin grijanja (kW): 5,6",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Ulazni model AUX klima uređaja svojom ponudom i brojnim “smart” rješenjima neće vas ostaviti ravnodušnima. Odlikuju ga niska potrošnja električne energije, vrhunska obrada zraka i tihi rad. AUX J-Smart lako će rashladiti željenu prostoriju i za vrijeme visokih vrućina, a u prijelaznim mjesecima može vam poslužiti i za dogrijavanje prostora. Naravno, ne smijemo zaboraviti napomenuti da ova serija koristi ekološki prihvatljiviji rashladni medij R32 koji ne samo da pomaže pri očuvanju okoliša, već i sam uređaj čini učinkovitijim.",
  "price": "831,51",
  "marka": "aux",
  "filename": "1700073300009-6.png"
},{
  "text": "AUX Q-Neo 2.7 kW - ARI-09QDH/ARO-09QDH",
  "desc1": "Učin hlađenja (kW): 2,7",
  "desc2": "Učin grijanja (kW): 2,9",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Ovaj dizajnerski model AUX klima uređaja podići će razinu estetike i hlađenja na zavidni nivo. Prije svega, odlikuju ga niska potrošnja električne energije, vrhunska obrada zraka i tihi rad. AUX Q-Neo s lakoćom će rashladiti željenu prostoriju i za vrijeme visokih vrućina i sparine, a u prijelaznim mjesecima poslužit će vam i za dogrijavanje prostora. Naravno, ne smijemo zaboraviti napomenuti da ova serija koristi ekološki prihvatljiviji rashladni medij R32 koji ne samo da pomaže pri očuvanju okoliša, već i sam uređaj čini učinkovitijim.",
  "price": "488,09",
  "marka": "aux",
  "filename": "1700073300009-6.png"
},{
  "text": "AUX Q-Neo 3.5 kW - ARI-12QDH/ARO-12QDH",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 3,8",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Ovaj dizajnerski model AUX klima uređaja podići će razinu estetike i hlađenja na zavidni nivo. Prije svega, odlikuju ga niska potrošnja električne energije, vrhunska obrada zraka i tihi rad. AUX Q-Neo s lakoćom će rashladiti željenu prostoriju i za vrijeme visokih vrućina i sparine, a u prijelaznim mjesecima poslužit će vam i za dogrijavanje prostora. Naravno, ne smijemo zaboraviti napomenuti da ova serija koristi ekološki prihvatljiviji rashladni medij R32 koji ne samo da pomaže pri očuvanju okoliša, već i sam uređaj čini učinkovitijim.",
  "price": "542,50",
  "marka": "aux",
  "filename": "1700073300009-6.png"
},{
  "text": "AUX Q-Neo 5.4 kW - ARI-18QDH/ARO-18QDH",
  "desc1": "Učin hlađenja (kW): 5,4",
  "desc2": "Učin grijanja (kW): 5,6",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Ovaj dizajnerski model AUX klima uređaja podići će razinu estetike i hlađenja na zavidni nivo. Prije svega, odlikuju ga niska potrošnja električne energije, vrhunska obrada zraka i tihi rad. AUX Q-Neo s lakoćom će rashladiti željenu prostoriju i za vrijeme visokih vrućina i sparine, a u prijelaznim mjesecima poslužit će vam i za dogrijavanje prostora. Naravno, ne smijemo zaboraviti napomenuti da ova serija koristi ekološki prihvatljiviji rashladni medij R32 koji ne samo da pomaže pri očuvanju okoliša, već i sam uređaj čini učinkovitijim.",
  "price": "859,88",
  "marka": "aux",
  "filename": "1700073300009-6.png"
},{
  "text": "AUX Q-Premium 2.7 kW - ARI-09QFHB/ARO-09QFH",
  "desc1": "Učin hlađenja (kW): 2,7",
  "desc2": "Učin grijanja (kW): 2,9",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Ovaj dizajnerski model AUX klima uređaja podići će razinu estetike i hlađenja na zavidni nivo. Prije svega, odlikuju ga niska potrošnja električne energije, vrhunska obrada zraka i tihi rad. AUX Q-Premium s lakoćom će rashladiti željenu prostoriju i za vrijeme visokih vrućina i sparine, a u prijelaznim mjesecima poslužit će vam i za dogrijavanje prostora. Naravno, ne smijemo zaboraviti napomenuti da ova serija koristi ekološki prihvatljiviji rashladni medij R32 koji ne samo da pomaže pri očuvanju okoliša, već i sam uređaj čini učinkovitijim.",
  "price": "514,97",
  "marka": "aux",
  "filename": "1700073300009-6.png"
},{
  "text": "AUX Q-Premium 3.5 kW - ARI-12QFHB/ARO-12QFH",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 3,8",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Ovaj dizajnerski model AUX klima uređaja podići će razinu estetike i hlađenja na zavidni nivo. Prije svega, odlikuju ga niska potrošnja električne energije, vrhunska obrada zraka i tihi rad. AUX Q-Premium s lakoćom će rashladiti željenu prostoriju i za vrijeme visokih vrućina i sparine, a u prijelaznim mjesecima poslužit će vam i za dogrijavanje prostora. Naravno, ne smijemo zaboraviti napomenuti da ova serija koristi ekološki prihvatljiviji rashladni medij R32 koji ne samo da pomaže pri očuvanju okoliša, već i sam uređaj čini učinkovitijim.",
  "price": "582,32",
  "marka": "aux",
  "filename": "1700073300009-6.png"
},{
  "text": "AUX Q-Premium 5.4 kW - ARI-18QFHB/ARO-18QFH",
  "desc1": "Učin hlađenja (kW): 5,4",
  "desc2": "Učin grijanja (kW): 5,6",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Ovaj dizajnerski model AUX klima uređaja podići će razinu estetike i hlađenja na zavidni nivo. Prije svega, odlikuju ga niska potrošnja električne energije, vrhunska obrada zraka i tihi rad. AUX Q-Premium s lakoćom će rashladiti željenu prostoriju i za vrijeme visokih vrućina i sparine, a u prijelaznim mjesecima poslužit će vam i za dogrijavanje prostora. Naravno, ne smijemo zaboraviti napomenuti da ova serija koristi ekološki prihvatljiviji rashladni medij R32 koji ne samo da pomaže pri očuvanju okoliša, već i sam uređaj čini učinkovitijim.",
  "price": "925,90",
  "marka": "aux",
  "filename": "1700073300009-6.png"
},{
  "text": "AUX HALO 2.7 kW - ARI-09HCH/ARO-09HCH",
  "desc1": "Učin hlađenja (kW): 2,7",
  "desc2": "Učin grijanja (kW): 3,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Top model AUX klima uređaja iznenaditi će vas ponudom i brojnim inovativnim rješenjima. Prije svega, odlikuju ga niska potrošnja električne energije, vrhunska obrada zraka i tihi rad. AUX HALO lako će rashladiti željenu prostoriju i za vrijeme iznimnih vrućina, a u prijelaznim mjesecima poslužit će vam i za dogrijavanje prostora. Naravno, ne smijemo zaboraviti napomenuti da ova serija koristi ekološki prihvatljiviji rashladni medij R32 koji ne samo da pomaže pri očuvanju okoliša, već i sam uređaj čini učinkovitijim.",
  "price": "566,39",
  "marka": "aux",
  "filename": "1700073300009-6.png"
},{
  "text": "AUX HALO 3.5 kW - ARI-12HCH/ARO-12HCH",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 4,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Top model AUX klima uređaja iznenaditi će vas ponudom i brojnim inovativnim rješenjima. Prije svega, odlikuju ga niska potrošnja električne energije, vrhunska obrada zraka i tihi rad. AUX HALO lako će rashladiti željenu prostoriju i za vrijeme iznimnih vrućina, a u prijelaznim mjesecima poslužit će vam i za dogrijavanje prostora. Naravno, ne smijemo zaboraviti napomenuti da ova serija koristi ekološki prihvatljiviji rashladni medij R32 koji ne samo da pomaže pri očuvanju okoliša, već i sam uređaj čini učinkovitijim.",
  "price": "614,17",
  "marka": "aux",
  "filename": "1700073300009-6.png"
},{
  "text": "Qzen Start Inverter Plus 2.6 kW",
  "desc1": "Učin hlađenja (kW): 2,6",
  "desc2": "Učin grijanja (kW): 2,9",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 2.6 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "410,17",
  "marka": "qzen",
  "filename": "1700073698897-7.png"
},{
  "text": "Qzen Start Inverter Plus 3.5 kW",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 3,8",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 3.5 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "434,06",
  "marka": "qzen",
  "filename": "1700073753790-7.png"
},{
  "text": "Qzen Start Inverter Plus 5.3 kW",
  "desc1": "Učin hlađenja (kW): 5,3",
  "desc2": "Učin grijanja (kW): 5,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 5.3 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "764,77",
  "marka": "qzen",
  "filename": "1700073806054-7.png"
},{
  "text": "Qzen Start Inverter Plus 2.6 kW + WIFI - ZE-09WSE/ZE-09OSE+WIFI",
  "desc1": "Učin hlađenja (kW): 2,6",
  "desc2": "Učin grijanja (kW): 2,9",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 2.6 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "426,60",
  "marka": "qzen",
  "filename": "1700073806054-7.png"
},{
  "text": "Qzen Start Inverter Plus 3.5 kW + WIFI - ZE-12WSE/ZE-12OSE+WIFI",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 3,8",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 3.5 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "450,49",
  "marka": "qzen",
  "filename": "1700073806054-7.png"
},{
  "text": "Qzen Start Inverter Plus 5.3 kW + WIFI - ZE-18WSE/ZE-18OSE+WIFI",
  "desc1": "Učin hlađenja (kW): 5,3",
  "desc2": "Učin grijanja (kW): 5,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 5.3 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "781,20",
  "marka": "qzen",
  "filename": "1700073806054-7.png"
},{
  "text": "Qzen Start Inverter Plus 7.0 kW - ZE-24WSE/ZE-24OSE",
  "desc1": "Učin hlađenja (kW): 7,0",
  "desc2": "Učin grijanja (kW): 7,3",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 66 - 75",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 7.0 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "996,75",
  "marka": "qzen",
  "filename": "1700073806054-7.png"
},{
  "text": "Qzen Start Inverter Plus 7.0 kW + WIFI - ZE-24WSE/ZE-24OSE+WIFI",
  "desc1": "Učin hlađenja (kW): 7,0",
  "desc2": "Učin grijanja (kW): 7,3",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 66 - 75",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 7.0 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "1.013,18",
  "marka": "qzen",
  "filename": "1700073806054-7.png"
},{
  "text": "Toshiba Seiya 2.0 kW",
  "desc1": "Učin hlađenja (kW): 2,0",
  "desc2": "Učin grijanja (kW): 2,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): do 15",
  "desc5": "",
  "desc6": "",
  "desc7": "Seiya Inverter predstavlja savršen ulazak u svijet klimatizacije. Početni model Toshibine palete opremljen je štedljivim inverterskim kompresorom i svim funkcionalnostima na koje su navikli korisnici premium branda poput Toshibe. Uz to, svoj rad obavlja koristeći najnoviji rashladni medij R32 koji ne samo da je ekološki prihvatljiviji, već i donosi dodatnu uštedu u potrošnji električne energije. Energetska učinkovitost u hlađenju s razredom A++ i snagom 2 kW.  No, nisu samo odlične značajke te koje ovaj model čine odličnim, tu je i primjer neprestanog rada na inovacijama koje će se pobrinuti za maksimalnu udobnost i mir. Seiya smanjuje razinu buke dvjema funkcijama tihog rada: QUIET na unutarnjoj jedinici omogućava vam neometani mir noću, a SILENT na vanjskoj jedinici vašim susjedima. I to sve samo jednim pritiskom gumba!",
  "price": "679,50",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Seiya 2.5 kW",
  "desc1": "Učin hlađenja (kW): 2,5",
  "desc2": "Učin grijanja (kW): 3,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Seiya Inverter predstavlja savršen ulazak u svijet klimatizacije. Početni model Toshibine palete opremljen je štedljivim inverterskim kompresorom i svim funkcionalnostima na koje su navikli korisnici premium branda poput Toshibe. Uz to, svoj rad obavlja koristeći najnoviji rashladni medij R32 koji ne samo da je ekološki prihvatljiviji, već i donosi dodatnu uštedu u potrošnji električne energije. Energetska učinkovitost je u razredu A++ te ima snagu od 2.5 kW.",
  "price": "732,38",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Seiya 3.3 kW - B13E2KVG-E/RAS-13E2AVG-E",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 3,6",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Seiya Inverter predstavlja savršen ulazak u svijet klimatizacije. Početni model Toshibine palete opremljen je štedljivim inverterskim kompresorom i svim funkcionalnostima na koje su navikli korisnici premium branda poput Toshibe. Uz to, svoj rad obavlja koristeći najnoviji rashladni medij R32 koji ne samo da je ekološki prihvatljiviji, već i donosi dodatnu uštedu u potrošnji električne energije. Energetska učinkovitost razreda A++ i snagom od 3.3 kW.",
  "price": "793,13",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Seiya 4.2 kW - RAS-B16E2KVG-E/RAS-16E2AVG-E",
  "desc1": "Učin hlađenja (kW): 4,2",
  "desc2": "Učin grijanja (kW): 5,0",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 36 - 45",
  "desc5": "",
  "desc6": "",
  "desc7": "Seiya Inverter predstavlja savršen ulazak u svijet klimatizacije. Početni model Toshibine palete opremljen je štedljivim inverterskim kompresorom i svim funkcionalnostima na koje su navikli korisnici premium branda poput Toshibe. Uz to, svoj rad obavlja koristeći najnoviji rashladni medij R32 koji ne samo da je ekološki prihvatljiviji, već i donosi dodatnu uštedu u potrošnji električne energije. Energetska učinkovitost razreda A++ i snagom od 4.2 kW. ",
  "price": "1.031,63",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Seiya 5.0 kW - RAS-18E2KVG-E/RAS-18E2AVG-E",
  "desc1": "Učin hlađenja (kW): 5,0",
  "desc2": "Učin grijanja (kW): 5,4",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Seiya Inverter predstavlja savršen ulazak u svijet klimatizacije. Početni model Toshibine palete opremljen je štedljivim inverterskim kompresorom i svim funkcionalnostima na koje su navikli korisnici premium branda poput Toshibe. Uz to, svoj rad obavlja koristeći najnoviji rashladni medij R32 koji ne samo da je ekološki prihvatljiviji, već i donosi dodatnu uštedu u potrošnji električne energije. Energetska učinkovitost razreda A++ i snagom od 5.0 kW. ",
  "price": "1.229,63",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Seiya 6.5 kW - RAS-24E2KVG-E/RAS-24E2AVG-E",
  "desc1": "Učin hlađenja (kW): 6,5",
  "desc2": "Učin grijanja (kW): 7,0",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 56 - 65",
  "desc5": "",
  "desc6": "",
  "desc7": "Seiya Inverter predstavlja savršen ulazak u svijet klimatizacije. Početni model Toshibine palete opremljen je štedljivim inverterskim kompresorom i svim funkcionalnostima na koje su navikli korisnici premium branda poput Toshibe. Uz to, svoj rad obavlja koristeći najnoviji rashladni medij R32 koji ne samo da je ekološki prihvatljiviji, već i donosi dodatnu uštedu u potrošnji električne energije. Energetska učinkovitost razreda A++ i snagom od 6.5 kW.",
  "price": "1.497,38",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Super Daiseikai 9 - 2.5 kW - RAS-10PKVPG-E/RAS-10PAVPG-E R-32",
  "desc1": "Učin hlađenja (kW): 2,5",
  "desc2": "Učin grijanja (kW): 3,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Toshibina perjanica premium razreda Daiseikai 9 opremljena je dvostruko-rotacijskim kompresorom vrhunske efikasnosti te postiže zavidne rezultate u potrošnji električne energije. Smanjenju potrošnje pridonosi i korištenje ekološki prihvatljivijeg rashladnog medija R32, dok se za kakvoću zraka brinu ugrađeni ionizator i napredni plazma filter.",
  "price": "1.447,88",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Super Daiseikai 9 - 3.5 kW - RAS-13PKVPG-E/RAS-13PAVPG-E R-32",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 3,6",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Toshibina perjanica premium razreda Daiseikai 9 opremljena je dvostruko-rotacijskim kompresorom vrhunske efikasnosti te postiže zavidne rezultate u potrošnji električne energije. Smanjenju potrošnje pridonosi i korištenje ekološki prihvatljivijeg rashladnog medija R32, dok se za kakvoću zraka brinu ugrađeni ionizator i napredni plazma filter.",
  "price": "1.596,38",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Super Daiseikai 9 - 4.5 kW - RAS-16PKVPG-E/RAS-16PAVPG-E R-32",
  "desc1": "Učin hlađenja (kW): 4,5",
  "desc2": "Učin grijanja (kW): 5,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Toshibina perjanica premium razreda Daiseikai 9 opremljena je dvostruko-rotacijskim kompresorom vrhunske efikasnosti te postiže zavidne rezultate u potrošnji električne energije. Smanjenju potrošnje pridonosi i korištenje ekološki prihvatljivijeg rashladnog medija R32, dok se za kakvoću zraka brinu ugrađeni ionizator i napredni plazma filter.",
  "price": "2.032,88",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Premium 2.5 kW - RAS-B10J2KVRG-E/RAS-10J2AVRG-E",
  "desc1": "Učin hlađenja (kW): 2,5",
  "desc2": "Učin grijanja (kW): 3,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Toshiba Shorai Premium model predstavlja kombinaciju vrhunskih modela klima uređaja Suzumi i Daisekai. Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "849,59",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge 2.0 kW - RAS-B07J2KVSG-E/RAS-07J2AVSG-E",
  "desc1": "Učin hlađenja (kW): 2,0",
  "desc2": "Učin grijanja (kW): 2,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): do 15",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "941,63",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge 2.5 kW - RAS-B10J2KVSG-E/RAS-10J2AVSG-E",
  "desc1": "Učin hlađenja (kW): 2,5",
  "desc2": "Učin grijanja (kW): 3,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "968,63",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge 3.5 kW - RAS-B13J2KVSG-E/RAS-13J2AVSG-E",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 4,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.123,88",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge 4.6 kW - RAS-B16J2KVSG-E/RAS-16J2AVSG-E",
  "desc1": "Učin hlađenja (kW): 4,6",
  "desc2": "Učin grijanja (kW): 5,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 35 - 45",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.317,38",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge 6.1 kW - RAS-B22J2KVSG-E/RAS-22J2AVSG-E",
  "desc1": "Učin hlađenja (kW): 6,1",
  "desc2": "Učin grijanja (kW): 7,0",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 56 - 65",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.758,38",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge 7.0 kW - RAS-B24J2KVSG-E/RAS-24J2AVSG-E",
  "desc1": "Učin hlađenja (kW): 7,0",
  "desc2": "Učin grijanja (kW): 8,0",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 66 - 75",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "2.041,88",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Haori 2.5 kW - RAS-B10N4KVRG-E/RAS-10J2AVSG-E",
  "desc1": "Učin hlađenja (kW): 2,5",
  "desc2": "Učin grijanja (kW): 3,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Uređaj Haori brzo se prilagođava svakom interijeru s pomoću dva priložena pokrova od tkanine. Kome to ne odgovara, na raspolaganju su još četiri dodatne boje pokrova ili se može odabrati vlastiti, individualni dizajn. Uređaj Haori ima najbolje vrijednosti učinkovitosti i opremljen je najnovijom tehnologijom filtera za zrak. WiFi adapter za upravljanje putem aplikacije serijski je uključen.",
  "price": "1.198,13",
  "marka": "toshiba",
  "filename": "1700074693302-8-black.png"
},{
  "text": "Toshiba Haori 3.5 kW - RAS-B13N4KVRG-E/RAS-13J2AVSG-E",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 4,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Uređaj Haori brzo se prilagođava svakom interijeru s pomoću dva priložena pokrova od tkanine. Kome to ne odgovara, na raspolaganju su još četiri dodatne boje pokrova ili se može odabrati vlastiti, individualni dizajn. Uređaj Haori ima najbolje vrijednosti učinkovitosti i opremljen je najnovijom tehnologijom filtera za zrak. WiFi adapter za upravljanje putem aplikacije serijski je uključen.",
  "price": "1.378,13",
  "marka": "toshiba",
  "filename": "1700074693302-8-black.png"
},{
  "text": "Toshiba Haori 4.6 kW - RAS-B16N4KVRG-E/RAS-16J2AVSG-E",
  "desc1": "Učin hlađenja (kW): 4,6",
  "desc2": "Učin grijanja (kW): 5,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 36 - 45",
  "desc5": "",
  "desc6": "",
  "desc7": "Uređaj Haori brzo se prilagođava svakom interijeru s pomoću dva priložena pokrova od tkanine. Kome to ne odgovara, na raspolaganju su još četiri dodatne boje pokrova ili se može odabrati vlastiti, individualni dizajn. Uređaj Haori ima najbolje vrijednosti učinkovitosti i opremljen je najnovijom tehnologijom filtera za zrak. WiFi adapter za upravljanje putem aplikacije serijski je uključen.",
  "price": "1.591,88",
  "marka": "toshiba",
  "filename": "1700074693302-8-black.png"
},{
  "text": "Toshiba Shorai Edge White 2.0 kW - RAS-B07G3KVSG-E/RAS-07J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 2,0",
  "desc2": "Učin grijanja (kW): 2,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): do 15",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.036,13",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge Black 2.0 kW - RAS-B07G3KVSGB-E/RAS-07J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 2,0",
  "desc2": "Učin grijanja (kW): 2,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): do 15",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.094,63",
  "marka": "toshiba",
  "filename": "1700074693302-8-black.png"
},{
  "text": "Toshiba Shorai Edge White 2.5 kW - RAS-B10G3KVSG-E/RAS-10J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 2,5",
  "desc2": "Učin grijanja (kW): 3,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.063,13",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge Black 2.5 kW - RAS-B10G3KVSGB-E/RAS-10J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 2,5",
  "desc2": "Učin grijanja (kW): 3,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.121,63",
  "marka": "toshiba",
  "filename": "1700074693302-8-black.png"
},{
  "text": "Toshiba Shorai Edge White 3.5 kW - RAS-B13G3KVSG-E/RAS-13J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 4,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.221,75",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge Black 3.5 kW - RAS-B13G3KVSGB-E/RAS-13J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 4,2",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.283,63",
  "marka": "toshiba",
  "filename": "1700074693302-8-black.png"
},{
  "text": "Toshiba Shorai Edge White 4.6 kW - RAS-B16G3KVSG-E/RAS-16J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 4,6",
  "desc2": "Učin grijanja (kW): 5,,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 36 - 45",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.436,63",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge Black 4.6 kW - RAS-B16G3KVSGB-E/RAS-16J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 4,6",
  "desc2": "Učin grijanja (kW): 5,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 36 - 45",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.517,63",
  "marka": "toshiba",
  "filename": "1700074693302-8-black.png"
},{
  "text": "Toshiba Shorai Edge White 5.0 kW - RAS-B18G3KVSG-E/RAS-18J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 5,0",
  "desc2": "Učin grijanja (kW): 6,,",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.667,25",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge Black 5.0 kW - RAS-B18G3KVSGB-E/RAS-18J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 5,0",
  "desc2": "Učin grijanja (kW): 6,0",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.759,50",
  "marka": "toshiba",
  "filename": "1700074693302-8-black.png"
},{
  "text": "Toshiba Shorai Edge White 6.1 kW - RAS-B22G3KVSG-E/RAS-22J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 6,1",
  "desc2": "Učin grijanja (kW): 7,0",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 55 - 65",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "1.911,38",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge Black 6.1 kW - RAS-B22G3KVSGB-E/RAS-22J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 6,1",
  "desc2": "Učin grijanja (kW): 7,0",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 55 - 65",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "2.012,63",
  "marka": "toshiba",
  "filename": "1700074693302-8-black.png"
},{
  "text": "Toshiba Shorai Edge White 7.0 kW - RAS-B24G3KVSG-E/RAS-24J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 7,0",
  "desc2": "Učin grijanja (kW): 8,0",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 66 - 75",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "2.225,25",
  "marka": "toshiba",
  "filename": "1700074693302-8.png"
},{
  "text": "Toshiba Shorai Edge Black 7.0 kW - RAS-B24G3KVSGB-E/RAS-24J2AVSG-E1",
  "desc1": "Učin hlađenja (kW): 7,0",
  "desc2": "Učin grijanja (kW): 8,0",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 66 - 75",
  "desc5": "",
  "desc6": "",
  "desc7": "Visoka energetska učinkovitost, napredni filterski sustav i 3D Airflow upravljanje lamelama samo su neke od brojnih karakteristika ovog primamljivog modela. Također, koristi se najnoviji rashladni medij R32 koji uz dodatnu energetsku uštedu ima i znatno smanjen negativan utjecaj na okoliš.",
  "price": "2.353,50",
  "marka": "toshiba",
  "filename": "1700074693302-8-black.png"
},{
  "text": "Fujitsu Standard Eco Inverter 2.5 kW",
  "desc1": "Učin hlađenja (kW): 2,5",
  "desc2": "Učin grijanja (kW): 2,8",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Ulazni Fujitsu Standard Eco Inverter model koji koristi rashladni medij R32 oduševit će vas jednostavnim, nenametljivim dizajnom koji skriva brojne tehnološke novitete. Uređaj postiže A++ energetski razred u hlađenju, podržava opcionalno WiFi upravljanje koje se kupuje zasebno, te može grijati sve do -15 ºC. Snagom od 2.5 kW zadovoljit će sve potrebe hlađenja i grijanja u prostorijama do 25 m².",
  "price": "572,07",
  "marka": "fujitsu",
  "filename": "1700075073755-9.png"
},{
  "text": "Mitsubishi Electric Standard Eco Inverter 2.5 kW",
  "desc1": "Učin hlađenja (kW): 2,5",
  "desc2": "Učin grijanja (kW): 3,15",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Srednji model iz palete uređaja Mitsubishi Electric koristi novi, ekološki prihvatljiv rashladni medij R32 i upravo zahvaljujući njemu i naprednoj inverterskoj tehnologiji spada u uređaje odlične energetske učinkovitosti razreda SEER A++ i SCOP A+. Mitsubishi Electric Standard Eco Inverter MSZ-HR25VF/MUZ-HR25VF odlikuje se jednostavnošću korištenja, kompaktnim dimenzijama i funkcijama nužnim za visoku razinu komfora.",
  "price": "573,37",
  "marka": "mitsubishi",
  "filename": "1700075147042-10.png"
},{
  "text": "Qzen Start Inverter Plus 2.6 kW",
  "desc1": "Učin hlađenja (kW): 2,6",
  "desc2": "Učin grijanja (kW): 2,9",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 2.6 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "410,17",
  "marka": "qzen",
  "filename": "qzenklima.png"
},{
  "text": "Qzen Start Inverter Plus 3.5 kW - ZE-12WSE/ZE-12OSE",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 3,8",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 3.5 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "434,06",
  "marka": "qzen",
  "filename": "qzenklima.png"
},{
  "text": "Qzen Start Inverter Plus 5.3 kW - ZE-18WSE/ZE-18OSE",
  "desc1": "Učin hlađenja (kW): 5,3",
  "desc2": "Učin grijanja (kW): 5,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 5.3 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "764,77",
  "marka": "qzen",
  "filename": "qzenklima.png"
},{
  "text": "Qzen Start Inverter Plus 2.6 kW + WIFI - ZE-09WSE/ZE-09OSE+WIFI",
  "desc1": "Učin hlađenja (kW): 2,6",
  "desc2": "Učin grijanja (kW): 2,9",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 16 - 25",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 2.6 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "426,60",
  "marka": "qzen",
  "filename": "qzenklima.png"
},{
  "text": "Qzen Start Inverter Plus 3.5 kW + WIFI - ZE-12WSE/ZE-12OSE+WIFI",
  "desc1": "Učin hlađenja (kW): 3,5",
  "desc2": "Učin grijanja (kW): 3,8",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 26 - 35",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 3.5 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "450,49",
  "marka": "qzen",
  "filename": "qzenklima.png"
},{
  "text": "Qzen Start Inverter Plus 5.3 kW + WIFI - ZE-18WSE/ZE-18OSE+WIFI",
  "desc1": "Učin hlađenja (kW): 5,3",
  "desc2": "Učin grijanja (kW): 5,5",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 46 - 55",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 5.3 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "781,20",
  "marka": "qzen",
  "filename": "qzenklima.png"
},{
  "text": "Qzen Start Inverter Plus 7.0 kW - ZE-24WSE/ZE-24OSE",
  "desc1": "Učin hlađenja (kW): 7,0",
  "desc2": "Učin grijanja (kW): 7,3",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 66 - 75",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 7.0 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "996,75",
  "marka": "qzen",
  "filename": "qzenklima.png"
},{
  "text": "Qzen Start Inverter Plus 7.0 kW + WIFI - ZE-24WSE/ZE-24OSE+WIFI",
  "desc1": "Učin hlađenja (kW): 7,0",
  "desc2": "Učin grijanja (kW): 7,3",
  "desc3": "Energetski razred: A++",
  "desc4": "Veličina prostora (m2): 66 - 75",
  "desc5": "",
  "desc6": "",
  "desc7": "Klima uređaj Qzen Start Inverter Plus 7.0 kW predstavlja idealno rješenje ako želite štedljiv uređaj s modernim tehnologijama i dodatnim funkcijama, ali po prihvatljivoj cijeni. Ovi odlični uređaji nalaze se u energetskom razredu A++ i zahvaljujući ekološki prihvatljivijem rashladnom mediju R32 osiguravaju još veću uštedu, efikasnost i naravno manje štetan utjecaj na okolinu. Dodatno, potrošnja za vrijeme mirovanja je smanjena na samo 1W.",
  "price": "1.013,18",
  "marka": "qzen",
  "filename": "qzenklima.png"
},{"text": "Fujitsu Standard Eco Inverter 2.5 kW - ASYG09KPCE/AOYG09KPCA",
"desc1": "Učin hlađenja (kW): 2,5",
"desc2": "Učin grijanja (kW): 2,8",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 16 - 25",
"price": "635,63 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Standard Eco Inverter 3.4 kW - ASYG12KPCE/AOYG12KPCA",
"desc1": "Učin hlađenja (kW): 3,4",
"desc2": "Učin grijanja (kW): 3,8",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 26 - 35",
"price": "698,63 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Standard Eco Inverter 5.2 kW - ASYG18KLCA/AOYG18KLCA",
"desc1": "Učin hlađenja (kW): 5,2",
"desc2": "Učin grijanja (kW): 6,3",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 46 - 55",
"price": "1.598,63 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Standard Eco Inverter 7.1 kW - ASYG24KLCA/AOYG24KLCA",
"desc1": "Učin hlađenja (kW): 7,1",
"desc2": "Učin grijanja (kW): 8,0",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 66 - 75",
"price": "1.964,25 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Super Eco Inverter 2.0 kW - ASYG07KMCC/AOYG07KMCC",
"desc1": "Učin hlađenja (kW): 2,0",
"desc2": "Učin grijanja (kW): 2,5",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): do 15",
"price": "804,38 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 3.4 kW - bijela - ASYG12KETA/AOYG12KETA",
"desc1": "Učin hlađenja (kW): 3,4",
"desc2": "Učin grijanja (kW): 4,0",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 26 - 35",
"price": "1.135,13 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 4.2 kW - bijela - ASYG14KETA/AOYG14KETA",
"desc1": "Učin hlađenja (kW): 4,2",
"desc2": "Učin grijanja (kW): 5,4",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 36 - 45",
"price": "1.535,63 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Super Eco Inverter - 5.2 kW - ASYG18KMTE/AOYG18KMTA",
"desc1": "Učin hlađenja (kW): 5,2",
"desc2": "Učin grijanja (kW): 6,3",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 46 - 55",
"price": "1.774,13 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Super Eco Inverter - 7.1 kW - ASYG24KMTE/AOYG24KMTA",
"desc1": "Učin hlađenja (kW): 7,1",
"desc2": "Učin grijanja (kW): 8,0",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 66 - 75",
"price": "2.187,01 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Super Eco Inverter 2.5 kW - ASYG09KMCE/AOYG09KMCC",
"desc1": "Učin hlađenja (kW): 2,5",
"desc2": "Učin grijanja (kW): 2,8",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 16 - 25",
"price": "850,51 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Super Eco Inverter 3.4 kW - ASYG12KMCE/AOYG12KMCC",
"desc1": "Učin hlađenja (kW): 3,4",
"desc2": "Učin grijanja (kW): 4,0",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 26 - 35",
"price": "983,26 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Super Eco Inverter 4.2 kW - ASYG14KMCE/AOYG14KMCC",
"desc1": "Učin hlađenja (kW): 4,2",
"desc2": "Učin grijanja (kW): 5,4",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 36 - 45",
"price": "1.329,75 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 2.5 kW - bijela - ASYG09KETE/AOYG09KETA",
"desc1": "Učin hlađenja (kW): 2,5",
"desc2": "Učin grijanja (kW): 2,8",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 16 - 25",
"price": "986,63 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 2.5 kW - siva - ASYG09KETE-B/AOYG09KETA",
"desc1": "Učin hlađenja (kW): 2,5",
"desc2": "Učin grijanja (kW): 2,8",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 16 - 25",
"price": "986,63 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 3.4 kW - siva - ASYG12KETE-B/AOYG12KETA",
"desc1": "Učin hlađenja (kW): 3,4",
"desc2": "Učin grijanja (kW): 4,0",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 26 - 35",
"price": "1.135,13 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 4.2 kW - siva - ASYG14KETE-B/AOYG14KETA",
"desc1": "Učin hlađenja (kW): 4,2",
"desc2": "Učin grijanja (kW): 5,4",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 36 - 45",
"price": "1.535,63 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Advance Inverter 2.5 kW - ASYG09KGTE/AOYG09KGCA",
"desc1": "Učin hlađenja (kW): 2,5",
"desc2": "Učin grijanja (kW): 2,8",
"desc3": "Energetski razred: A+++",
"desc4": "Veličina prostora (m2): 16 - 25",
"price": "1.054,13 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Advance Inverter 2.0 kW - ASYG07KGTE/AOYG07KGCB",
"desc1": "Učin hlađenja (kW): 2,0",
"desc2": "Učin grijanja (kW): 2,5",
"desc3": "Energetski razred: A+++",
"desc4": "Veličina prostora (m2): do 15",
"price": "986,63 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Advance Inverter 3.4 kW - ASYG12KGTF/AOYG12KGCB",
"desc1": "Učin hlađenja (kW): 3,4",
"desc2": "Učin grijanja (kW): 4,0",
"desc3": "Energetski razred: A+++",
"desc4": "Veličina prostora (m2): 26 - 35",
"price": "1.262,25 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Advance Inverter 4.2 kW - ASYG14KGTF/AOYG14KGCB",
"desc1": "Učin hlađenja (kW): 4,2",
"desc2": "Učin grijanja (kW): 5,4",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 36 - 45",
"price": "1.546,88 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Advance Inverter 2.0 kW - ASYG07KGTF/AOYG07KGCB",
"desc1": "Učin hlađenja (kW): 2,0",
"desc2": "Učin grijanja (kW): 2,5",
"desc3": "Energetski razred: A+++",
"desc4": "Veličina prostora (m2): do 15",
"price": "1.027,13 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Advance Inverter 2.5 kW - ASYG09KGTF/AOYG09KGCB",
"desc1": "Učin hlađenja (kW): 2,5",
"desc2": "Učin grijanja (kW): 2,8",
"desc3": "Energetski razred: A+++",
"desc4": "Veličina prostora (m2): 16 - 25",
"price": "1.094,63 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Super Eco Inverter 2.0 kW - ASYG07KMCF/AOYG07KMCC",
"desc1": "Učin hlađenja (kW): 2,0",
"desc2": "Učin grijanja (kW): 2,5",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): do 15",
"price": "844,88 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 2.5 kW - bijela - ASYG09KETF/AOYG09KETA",
"desc1": "Učin hlađenja (kW): 2,5",
"desc2": "Učin grijanja (kW): 2,8",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 16 - 25",
"price": "1.027,13 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Super Eco Inverter 2.5 kW - ASYG09KMCF/AOYG09KMCC",
"desc1": "Učin hlađenja (kW): 2,5",
"desc2": "Učin grijanja (kW): 2,8",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 16 - 25",
"price": "928,13 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Super Eco Inverter 3.4 kW - ASYG12KMCF/AOYG12KMCC",
"desc1": "Učin hlađenja (kW): 3,4",
"desc2": "Učin grijanja (kW): 4,0",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 26 - 35",
"price": "1.023,76 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Super Eco Inverter 4.2 kW - ASYG14KMCF/AOYG14KMCC",
"desc1": "Učin hlađenja (kW): 4,2",
"desc2": "Učin grijanja (kW): 5,4",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 36 - 45",
"price": "1.370,25 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 3.4 kW - siva - ASYG12KETF-B/AOYG12KETA",
"desc1": "Učin hlađenja (kW): 3,4",
"desc2": "Učin grijanja (kW): 4,0",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 26 - 35",
"price": "1.175,63 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 2.5 kW - siva - ASYG09KETF-B/AOYG09KETA",
"desc1": "Učin hlađenja (kW): 2,5",
"desc2": "Učin grijanja (kW): 2,8",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 16 - 25",
"price": "1.027,13 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 3.4 kW - bijela - ASYG12KETF/AOYG12KETA",
"desc1": "Učin hlađenja (kW): 3,4",
"desc2": "Učin grijanja (kW): 4,0",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 26 - 35",
"price": "1.175,63 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 4.2 kW - bijela - ASYG14KETF/AOYG14KETA",
"desc1": "Učin hlađenja (kW): 4,2",
"desc2": "Učin grijanja (kW): 5,4",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 36 - 45",
"price": "1.576,13 €",
"filename": "fujitsuklima.png",
"marka": "fujitsu",
},
{"text": "Fujitsu Design Inverter 4.2 kW - siva - ASYG14KETF-B/AOYG14KETA",
"desc1": "Učin hlađenja (kW): 4,2",
"desc2": "Učin grijanja (kW): 5,4",
"desc3": "Energetski razred: A++",
"desc4": "Veličina prostora (m2): 36 - 45",
"price": "1.576,13",
"filename": "fujitsuklima.png",
"marka": "fujitsu"},
{
    "text": "Mitsubishi Electric Standard Eco Inverter 2.5 kW - MSZ-HR25VF/MUZ-HR25VF",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,15",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "645,03 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Standard Eco Inverter 3.5 kW - MSZ-HR35VF/MUZ-HR35VF",
    "desc1": "Učin hlađenja (kW): 3,4",
    "desc2": "Učin grijanja (kW): 3,6",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "740,59 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Comfort Inverter 2.5 kW - MSZ-DW25VF/MUZ-DW25VF",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,15",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "619,65 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Comfort Inverter 3.4 kW - MSZ-DW35VF/MUZ-DW35VF",
    "desc1": "Učin hlađenja (kW): 3,4",
    "desc2": "Učin grijanja (kW): 3,6",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "715,22 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Standard Eco Inverter 4.2 kW - MSZ-HR42VF/MUZ-HR42VF",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 4,7",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "1.073,56 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Standard Eco Inverter 5.0 kW - MSZ-HR50VF/MUZ-HR50VF",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "1.203,47 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Comfort Inverter 5.0 kW - MSZ-DW50VF/MUZ-DW50VF",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "1.124,33 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Standard Eco Inverter 2.5 kW - MSZ-HR25VFK/MUZ-HR25VF",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,15",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "697,29 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Standard Eco Inverter 3.5 kW - MSZ-HR35VFK/MUZ-HR35VF",
    "desc1": "Učin hlađenja (kW): 3,4",
    "desc2": "Učin grijanja (kW): 3,6",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "800,32 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Standard Eco Inverter 4.2 kW - MSZ-HR42VFK/MUZ-HR42VF",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 4,7",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "1.139,25 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Standard Eco Inverter 5.0 kW - MSZ-HR50VFK/MUZ-HR50VF",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "1.266,18 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Standard Eco Inverter 6.1 kW - MSZ-HR60VFK/MUZ-HR60VF",
    "desc1": "Učin hlađenja (kW): 6,1",
    "desc2": "Učin grijanja (kW): 6,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 56 - 65",
    "price": "1.591,68 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Heating Inverter 2.5 kW - MSZ-FT25VGK/MUZ-FT25VGHZ",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "1.466,26 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Heating Inverter 3.5 kW - MSZ-FT35VGK/MUZ-FT35VGHZ",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "1.740,99 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Heating Inverter 5.0 kW - MSZ-FT50VGK/MUZ-FT50VGHZ",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "2.563,71 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 2.5 kW bijeli - MSZ-LN25VGW/MUZ-LN25VG",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "1.594,67 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 2.5 kW biserno bijeli - MSZ-LN25VGV/MUZ-LN25VG",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "1.706,65 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 2.5 kW onyx crni - MSZ-LN25VGB/MUZ-LN25VG",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "1.706,65 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 2.5 kW rubin crveni - MSZ-LN25VGR/MUZ-LN25VG",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "1.706,65 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 3.5 kW bijeli - MSZ-LN35VGW/MUZ-LN35VG",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "1.923,16 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 3.5 kW biserno bijeli - MSZ-LN35VGV/MUZ-LN35VG",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "2.069,48 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 3.5 kW onyx crni - MSZ-LN35VGB/MUZ-LN35VG",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "2.069,48 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 3.5 kW rubin crveni - MSZ-LN35VGR/MUZ-LN35VG",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "2.069,48 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 5.0 kW bijeli - MSZ-LN50VGW/MUZ-LN50VG",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 6,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "2.730,94 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 5.0 kW biserno bijeli - MSZ-LN50VGV/MUZ-LN50VG",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 6,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "2.899,66 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 5.0 kW onyx crni - MSZ-LN50VGB/MUZ-LN50VG",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 6,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "2.899,66 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 5.0 kW rubin crveni - MSZ-LN50VGR/MUZ-LN50VG",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 6,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "2.899,66 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 6.1 kW bijeli - MSZ-LN60VGW/MUZ-LN60VG",
    "desc1": "Učin hlađenja (kW): 6,1",
    "desc2": "Učin grijanja (kW): 6,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 56 - 65",
    "price": "2.931,02 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 6.1 kW biserno bijeli - MSZ-LN60VGV/MUZ-LN60VG",
    "desc1": "Učin hlađenja (kW): 6,1",
    "desc2": "Učin grijanja (kW): 6,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 56 - 65",
    "price": "3.119,15 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 6.1 kW onyx crni - MSZ-LN60VGB/MUZ-LN60VG",
    "desc1": "Učin hlađenja (kW): 6,1",
    "desc2": "Učin grijanja (kW): 6,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 56 - 65",
    "price": "3.119,15 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Style 6.1 kW rubin crveni - MSZ-LN60VGR/MUZ-LN60VG",
    "desc1": "Učin hlađenja (kW): 6,1",
    "desc2": "Učin grijanja (kW): 6,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 56 - 65",
    "price": "3.119,15 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 2.5 kW bijeli - MSZ-EF25VGKW/MUZ-EF25VG",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "1.272,14 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 3.5 kW bijeli - MSZ-EF35VGKW/MUZ-EF35VG",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "1.503,58 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 2.5 kW crni - MSZ-EF25VGKB/MUZ-EF25VG",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "1.272,14 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 2.5 kW srebrni - MSZ-EF25VGKS/MUZ-EF25VG",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "1.319,92 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 4.2 kW bijeli - MSZ-EF42VGKW/MUZ-EF42VG",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "1.958,99 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 3.5 kW crni - MSZ-EF35VGKB/MUZ-EF35VG",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "1.503,58 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 3.5 kW srebrni - MSZ-EF35VGKS/MUZ-EF35VG",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "1.552,86 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 4.2 kW crni - MSZ-EF42VGKB/MUZ-EF42VG",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "1.958,99 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 4.2 kW srebrni - MSZ-EF42VGKS/MUZ-EF42VG",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "2.009,75 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 5.0 kW bijeli - MSZ-EF50VGKW/MUZ-EF50VG",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "2.397,97 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 5.0 kW crni - MSZ-EF50VGKB/MUZ-EF50VG",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "2.397,97 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Kirigamine Zen Inverter 5.0 kW srebrni - MSZ-EF50VGKS/MUZ-EF50VG",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "2.442,76 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Hyper Heating DC Inverter 2.5 kW - MSZ-RW25VG/MUZ-RW25VGHZ",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 15 - 25",
    "price": "1.848,50 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Hyper Heating DC Inverter 3.5 kW - MSZ-RW35VG/MUZ-RW35VGHZ",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "2.211,33 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Hyper Heating DC Inverter 5.0 kW - MSZ-RW50VG/MUZ-RW50VGHZ",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 6,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "2.966,85 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Super Inverter Plus 2.5 kW - MSZ-AY25VGKP/MUZ-AY25VG",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "1.175,63 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Super Inverter Plus 3.5 kW - MSZ-AY35VGKP/MUZ-AY35VG",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A+++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "1.323,01 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Super Inverter Plus 4.2 kW - MSZ-AY42VGKP/MUZ-AY42VG",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "1.712,26 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Super Inverter Plus 5.0 kW - MSZ-AY50VGKP/MUZ-AY50VG",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,5",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "1.994,63 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Super Inverter Plus 6.1 kW - MSZ-AP60VGK/MUZ-AP60VG",
    "desc1": "Učin hlađenja (kW): 6,1",
    "desc2": "Učin grijanja (kW): 6,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 56 - 65",
    "price": "2.045,59 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Super Inverter Plus 7.1 kW - MSZ-AP71VGK/MUZ-AP71VG",
    "desc1": "Učin hlađenja (kW): 7,1",
    "desc2": "Učin grijanja (kW): 8,1",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 66 - 75",
    "price": "2.439,77 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Super Inverter Plus 4.2 kW - MSZ-AP42VG/MUZ-AP42VG",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "1.585,71 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Power Inverter 3.6 kW - zidna - PKA-M35HAL/PUZ-ZM35VKA",
    "desc1": "Učin hlađenja (kW): 3,6",
    "desc2": "Učin grijanja (kW): 4,1",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): -",
    "price": "2.869,80 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Power Inverter 4.6 kW - zidna - PKA-M50HAL/PUZ-ZM50VKA",
    "desc1": "Učin hlađenja (kW): 4,6",
    "desc2": "Učin grijanja (kW): 5,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): -",
    "price": "3.093,77 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Power Inverter 6.1 kW - zidna - PKA-M60KAL/PUZ-ZM60VHA",
    "desc1": "Učin hlađenja (kW): 6,1",
    "desc2": "Učin grijanja (kW): 7,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): -",
    "price": "3.671,61 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Power Inverter 7.1 kW - zidna - PKA-M71KAL/PUZ-ZM71VHA",
    "desc1": "Učin hlađenja (kW): 7,1",
    "desc2": "Učin grijanja (kW): 8,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): -",
    "price": "3.876,17 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Power Inverter 9.5 kW trofazna - zidna - PKA-M100KAL/PUZ-ZM100YKA",
    "desc1": "Učin hlađenja (kW): 9,5",
    "desc2": "Učin grijanja (kW): 11,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): -",
    "price": "5.085,60 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Mitsubishi Electric Power Inverter 9.5 kW - zidna - PKA-M100KAL/PUZ-ZM100VKA",
    "desc1": "Učin hlađenja (kW): 9,5",
    "desc2": "Učin grijanja (kW): 11,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): -",
    "price": "4.990,04 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-BT35VG-E1 - unutarnja jedinica - zidna - 1210104",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 3,6",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "319,53 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF25VGKW-E1 - unutarnja jedinica - zidna - 1218176",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "536,03 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF25VGKB-E1 - unutarnja jedinica - zidna - 1218177",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "536,03 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF25VGKS-E1 - unutarnja jedinica - zidna - 1218178",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "583,81 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF35VGKW-E1- unutarnja jedinica - zidna - 1218179",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "612,18 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF35VGKB-E1 - unutarnja jedinica - zidna - 1218180",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "612,18 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-EF35VGKS-E1 - unutarnja jedinica - zidna - 1218181",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "661,46 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-EF42VGKW-E1 - unutarnja jedinica - zidna - 1218182",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "698,79 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF42VGKB-E1 - unutarnja jedinica - zidna - 1218183",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "698,79 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-EF42VGKS-E1 - unutarnja jedinica - zidna - 1218184",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "749,55 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF50VGKW-E1 - unutarnja jedinica - zidna - 1218185",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "774,94 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF50VGKB-E1 - unutarnja jedinica - zidna - 1218186",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "774,94 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF50VGKS-E1 - unutarnja jedinica - zidna - 1218187",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "819,73 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-EF22VGKW-E1 - unutarnja jedinica - zidna - 1218173",
    "desc1": "Učin hlađenja (kW): 2,2",
    "desc2": "Učin grijanja (kW): 3,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "530,06 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF22VGKB-E1 - unutarnja jedinica - zidna - 1218174",
    "desc1": "Učin hlađenja (kW): 2,2",
    "desc2": "Učin grijanja (kW): 3,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "530,06 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-EF22VGKS-E1 - unutarnja jedinica - zidna - 1218175",
    "desc1": "Učin hlađenja (kW): 2,2",
    "desc2": "Učin grijanja (kW): 3,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "582,32 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-AP20VGK-E1 - unutarnja jedinica - zidna - 1214114",
    "desc1": "Učin hlađenja (kW): 2,0",
    "desc2": "Učin grijanja (kW): 2,5",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): do 15",
    "price": "419,57 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-BT35VGK-E1 - unutarnja jedinica - zidna - 1210108",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 3,6",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "362,83 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN25VG2B - unutarnja jedinica - zidna - MSZ-LN25VG2B",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "762,99 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN25VG2V - unutarnja jedinica - zidna - MSZ-LN25VG2V",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "762,99 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN25VG2W - unutarnja jedinica - zidna - MSZ-LN25VG2W",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "651,01 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN25VGR - unutarnja jedinica - zidna - MSZ-LN25VGR",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "762,99 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN35VG2B - unutarnja jedinica - zidna - MSZ-LN35VG2B",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "903,34 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN35VGR - unutarnja jedinica - zidna - MSZ-LN35VGR",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "903,34 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN35VG2W - unutarnja jedinica - zidna - MSZ-LN35VG2W",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "757,02 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN35VG2V - unutarnja jedinica - zidna - MSZ-LN35VG2V",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "903,34 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN50VG2B - unutarnja jedinica - zidna - MSZ-LN50VG2B",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 6,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "963,07 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN50VG2V - unutarnja jedinica - zidna - MSZ-LN50VG2V",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 6,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "963,07 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN50VG2W - unutarnja jedinica - zidna - MSZ-LN50VG2W",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 6,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "794,35 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AY25VGKP - unutarnja jedinica - zidna - MSZ-AY25VGKP",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "510,75 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AY35VGKP - unutarnja jedinica - zidna - MSZ-AY35VGKP",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "559,13 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AY42VGKP - unutarnja jedinica - zidna - MSZ-AY42VGKP",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "644,63 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AY50VGKP - unutarnja jedinica - zidna - MSZ-AY50VGKP",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,5",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "702,00 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AP25VGK - unutarnja jedinica - zidna - MSZ-AP25VGK",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "422,56 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AP35VGK - unutarnja jedinica - zidna - MSZ-AP35VGK",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "471,83 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AP42VGK - unutarnja jedinica - zidna - MSZ-AP42VGK",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "550,97 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AP50VGK - unutarnja jedinica - zidna - MSZ-AP50VGK",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "601,73 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AP60VGK - unutarnja jedinica - zidna - MSZ-AP60VGK",
    "desc1": "Učin hlađenja (kW): 6,1",
    "desc2": "Učin grijanja (kW): 6,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 56 - 65",
    "price": "648,02 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AP71VGK - unutarnja jedinica - zidna - MSZ-AP71VGK",
    "desc1": "Učin hlađenja (kW): 7,1",
    "desc2": "Učin grijanja (kW): 8,1",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 66 - 75",
    "price": "749,55 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF25VGKB - unutarnja jedinica - zidna - MSZ-EF25VGKB",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "536,03 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF25VGKS - unutarnja jedinica - zidna - MSZ-EF25VGKS",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "583,81 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF25VGKW - unutarnja jedinica - zidna - MSZ-EF25VGKW",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,2",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "536,03 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF35VGKB - unutarnja jedinica - zidna - MSZ-EF35VGKB",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "612,18 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-EF35VGKS- unutarnja jedinica - zidna - MSZ-EF35VGKS",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "661,46 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF35VGKW- unutarnja jedinica - zidna - MSZ-EF35VGKW",
    "desc1": "Učin hlađenja (kW): 3,5",
    "desc2": "Učin grijanja (kW): 4,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "612,18 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-LN50VG2R - unutarnja jedinica - zidna - MSZ-LN50VG2R",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 6,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "963,07 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-DW25VF - unutarnja jedinica - zidna - MSZ-DW25VF",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,15",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "253,83 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-DW35VF - unutarnja jedinica - zidna - MSZ-DW35VF",
    "desc1": "Učin hlađenja (kW): 3,4",
    "desc2": "Učin grijanja (kW): 3,6",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "283,70 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF22VGKB - unutarnja jedinica - zidna - MSZ-EF22VGKB",
    "desc1": "Učin hlađenja (kW): 2,2",
    "desc2": "Učin grijanja (kW): 3,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "530,06 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-EF22VGKS - unutarnja jedinica - zidna - MSZ-EF22VGKS",
    "desc1": "Učin hlađenja (kW): 2,2",
    "desc2": "Učin grijanja (kW): 3,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "582,32 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-EF22VGKW - unutarnja jedinica - zidna - MSZ-EF22VGKW",
    "desc1": "Učin hlađenja (kW): 2,2",
    "desc2": "Učin grijanja (kW): 3,0",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "530,06 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF42VGKB - unutarnja jedinica - zidna - MSZ-EF42VGKB",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "698,79 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-EF42VGKS - unutarnja jedinica - zidna - MSZ-EF42VGKS",
    "desc1": "Učin hlađenja (kW): 4,2",
    "desc2": "Učin grijanja (kW): 5,4",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 36 - 45",
    "price": "749,55 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF50VGKB - unutarnja jedinica - zidna - MSZ-EF50VGKB",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "774,94 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF50VGKS - unutarnja jedinica - zidna - MSZ-EF50VGKS",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "819,73 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi electric MSZ-EF50VGKW - unutarnja jedinica - zidna - MSZ-EF50VGKW",
    "desc1": "Učin hlađenja (kW): 5,0",
    "desc2": "Učin grijanja (kW): 5,8",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 46 - 55",
    "price": "774,94 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-HR25VFK - unutarnja jedinica - zidna - MSZ-HR25VFK",
    "desc1": "Učin hlađenja (kW): 2,5",
    "desc2": "Učin grijanja (kW): 3,15",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 16 - 25",
    "price": "316,54 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-HR35VF - unutarnja jedinica - zidna - MSZ-HR35VF",
    "desc1": "Učin hlađenja (kW): 3,4",
    "desc2": "Učin grijanja (kW): 3,6",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "297,13 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-HR35VFK - unutarnja jedinica - zidna - MSZ-HR35VFK",
    "desc1": "Učin hlađenja (kW): 3,4",
    "desc2": "Učin grijanja (kW): 3,6",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): 26 - 35",
    "price": "356,86 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AY15VGKP - unutarnja jedinica - zidna - MSZ-AY15VGKP",
    "desc1": "Učin hlađenja (kW): 1.5",
    "desc2": "Učin grijanja (kW): 1.7",
    "desc3": "Energetski razred: A++",
    "desc4": "Veličina prostora (m2): do 15",
    "price": "478,13 €",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
},
{
    "text": "Klima Mitsubishi Electric MSZ-AY20VGKP - unutarnja jedinica - zidna - MSZ-AY20VGKP",
    "desc1": "Učin hlađenja (kW): 2.0",
    "desc2": "Učin grijanja (kW): 2.5",
    "desc3": "Veličina prostora (m2): do 15",
    "desc4": "",
    "price": "498,38",
    "desc7": "",
    "filename": "mitsubishiklima.png",
    "marka": "mitsubishi",
}],

      selectedSort: 'all',
      loading: false,
      showDetailsModal: false,
      selectedItem: null,
      displayedItems: [],
      itemsToShow: 10,
      isMobile: false,
    };
  },
  created()
  {
    this.isMobile = this.checkMobile;
    window.addEventListener('resize', this.onResize);
    if (this.isMobile)
    {
      this.itemsToShow = 6
    }
    this.displayedItems = this.filteredItems.slice(0, this.itemsToShow);
    if (this.selectedKlima)
    {
      this.selectedSort = this.selectedKlima
    }
  },
  beforeUnmount()
  {
    window.removeEventListener('resize', this.onResize);
  },
  computed:
  {
    noItemsToShow()
    {
      return this.items.every(item => item.marka !== this.selectedSort) && this.selectedSort !== 'all';
    },
    checkMobile()
    {
      return window.innerWidth < 768;
    },
    filteredItems() {
      if (this.selectedSort === 'all') {
        return this.items;
      } else {
        return this.items.filter(item => item.marka === this.selectedSort);
      }
    }
  },
  methods:
  {
    onResize()
    {
      this.isMobile = this.checkMobile;
    },
    showMoreItems() {
      const endIndex = Math.min(
        this.displayedItems.length + this.itemsToShow,
        this.filteredItems.length
      );
      this.displayedItems = this.filteredItems.slice(0, endIndex);
    },

    getHRKprice(price)
    {
      return parseInt(price, 10) * 7.53450
    },
  
    openDetailsModal(item)
    {
      this.showDetailsModal = true
      this.selectedItem = item
    },
    closeDetailsModal()
    {
      this.showDetailsModal = false
      this.selectedItem = null
    },
    getImageSrc(filename)
    {
      return require(`@/assets/images/${filename}`);
    },
        updateDisplayedItems() {
      // Reset displayed items and show the first batch
      this.displayedItems = this.filteredItems.slice(0, this.itemsToShow);
    }

  },
  watch: 
  {
    selectedSort(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateDisplayedItems();
      }
    },
    'selectedKlima'(val)
    {
        this.selectedSort = val
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin-top: 3%;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.box-container{
  margin-left: 10%;
  margin-bottom: 5%;
  display: flex;
  flex-wrap: wrap;
}

.wtf-box{
  max-width: 18%;
  padding: 0.2rem;
  margin-bottom: 1%;
}

.loader {
  width: 120px;
  height: 150px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-image: linear-gradient(#ddd 50%, #bbb 51%),
  linear-gradient(#ddd, #ddd), linear-gradient(#ddd, #ddd),
  radial-gradient(ellipse at center, #aaa 25%, #eee 26%, #eee 50%, #0000 55%),
  radial-gradient(ellipse at center, #aaa 25%, #eee 26%, #eee 50%, #0000 55%),
  radial-gradient(ellipse at center, #aaa 25%, #eee 26%, #eee 50%, #0000 55%);
  background-position: 0 20px, 45px 0, 8px 6px, 55px 3px, 75px 3px, 95px 3px;
  background-size: 100% 4px, 1px 23px, 30px 8px, 15px 15px, 15px 15px, 15px 15px;
  position: relative;
  border-radius: 6%;
  animation: shake 3s ease-in-out infinite;
  transform-origin: 60px 180px;
}

.loader:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 100%;
  width: 7px;
  height: 5px;
  background: #aaa;
  border-radius: 0 0 4px 4px;
  box-shadow: 102px 0 #aaa;
}

.loader:after {
  content: "";
  position: absolute;
  width: 95px;
  height: 95px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 20px;
  background-color: #bbdefb;
  background-image: 
    linear-gradient( to right, #0004 0%, #0004 49%, #0000 50%, #0000 100% ),
    linear-gradient(135deg, #64b5f6 50%, #607d8b 51%);
  background-size: 30px 100%, 90px 80px;
  border-radius: 50%;
  background-repeat: repeat, no-repeat;
  background-position: 0 0;
  box-sizing: border-box;
  border: 10px solid #DDD;
  box-shadow: 0 0 0 4px #999 inset, 0 0 6px 6px #0004 inset;
  animation: spin 3s ease-in-out infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(360deg) }
  75% { transform: rotate(750deg) }
  100% { transform: rotate(1800deg) }
}
@keyframes shake {
  65%, 80%, 88%, 96% { transform: rotate(0.5deg) }
  50%, 75%, 84%, 92% { transform: rotate(-0.5deg) }
  0%, 50%, 100%  { transform: rotate(0) }
}

.box {
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  margin: 1%;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s;
  overflow: hidden;
}

.box:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}

.box img {
  margin-left: 20%;
  width: 40%;
  height: 40%;
  object-fit: contain;
}

.content {
  padding: 20px;
}

.content h2 {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
  min-height: 3rem;
}

.number {
  color: #007bff;
  font-size: 14px;
  font-weight: bold;
}

.button {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  font-size: 10px;
  border: 1px solid #007bff;
}

.button:hover{
  background: #fff;
  color: darkblue;
}

.button-add-more {
  width: 7rem;
  font-size: 16px;
  margin-left: 45%;
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid #007bff;
}

.button-add-more:hover{
  background: #fff;
  color: darkblue;
}
.bullet-list {
  margin-top: 10px;
  list-style-type: disc;
  padding-left: 20px;
}

.bullet-list p {
  font-size: 10px;
  margin-bottom: 5px;
}

.sort-section {
  margin-top: 5%;
  margin-left: 40%;
}

.noitemimage {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}


label {
  margin-right: 10px;
}

@media screen and (max-width: 60.625rem) { 
 
  .box-container {
    /* margin-bottom: 50%; */
  }

  .box {
    max-width: 80%;
  }

  .sort-section {
    margin-top: 20%;
    margin-left: 6%;
    padding-bottom: 5%;
  }

  .noitemimage {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .wtf-box{
    min-width: 100%;
    padding: 0;
    margin-bottom: 2%;
  }
  .box img {
    margin-left: 30%;
  }

  .button-add-more {
    width: 7rem;
    font-size: 16px;
    margin-left: 35%;
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    border: 1px solid #007bff;
  }

  .button-add-more:hover{
    background: #fff;
    color: darkblue;
  }
}
</style>
