<template>
  <footer>
    <p>
        IGK-Skrbin,
        obrt za instalacije grijanja i klime,
        vl. Skrbin Slavek,
        Donji Hruševec, Donji Hruševec 10

        OIB: 21292097323
        IBAN: HR9123900011101376973

        +385 99 407 6827

        s.slavek@live.com
    </p>
  </footer>
</template>

<script>
export default
{
    name: 'FooterPage'
};
</script>

<style scoped>
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eee;
  text-align: center;
  font-size: 12px;
}
@media screen and (max-width: 60.625rem) { 
  footer {
    font-size: 10px;
  }
}
</style>
