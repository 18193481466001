<template>
<body>
  <main>
    <div class="card">
      <div class="card__title">
        <div class="icon">
          <a href="#"><i class="fa fa-arrow-left"></i></a>
        </div>
      </div>
      <div class="card__body">
        <div class="half">
          <div class="featured_text">
            <h1>{{item.marka}}</h1>
            <p class="sub">{{item.text}}</p>
            <p class="price">{{item.price}} €</p>
          </div>
          <div class="image" v-if="!isMobile">
            <img :src="getImageSrc(item.filename)" alt="Item Image" />
          </div>
          <div class="description" v-else>
            <p>{{item.desc7}}</p>
          </div>
        </div>
        <div class="half" v-if="!isMobile">
          <div class="description">
            <p>{{item.desc7}}</p>
          </div>
        </div>
        <div class="image" v-else>
          <img :src="getImageSrc(item.filename)" alt="Item Image" />
        </div>
      </div>
      <div class="card__footer">
        <div class="action">
          <!-- <button @click="redirectToContact()">Pošalji upit!</button> -->
          <button @click="closeModal">Zatvori prozor</button>
        </div>
      </div>
    </div>
  </main>
</body>
</template>

<script>

export default
{
  name:'DetailsPage',
  props: ['item'],
  emits: ['closeModal', 'redirect'],
  data()
  {
    return {
      isMobile: false,
    };
  },
  created() {
    this.isMobile = this.checkMobile;
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount()
  {
    window.removeEventListener('resize', this.onResize);
  },
  computed:
  {
    checkMobile()
    {
      return window.innerWidth < 768;
    },
  },
  methods:
  {
    onResize()
    {
      this.isMobile = this.checkMobile;
    },
    getHRKprice(price)
    {
      return parseInt(price, 10) * 7.53450
    },
    closeModal()
    {
        this.$emit('closeModal');
    },
    getImageSrc(filename)
    {
      return require(`@/assets/images/${filename}`);
    },
  }
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css?family=Fjalla+One|Montserrat:300,400,700,800|Open+Sans:300');

body {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

main {
  max-width: 720px;
  margin: 5% auto;
}

.card {
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
  transition: 200ms;
  background: #fff;
}

.card__title {
  display: flex;
  align-items: center;
  padding: 30px 40px;
}

.card__title h3 {
  flex: 0 1 200px;
  text-align: right;
  margin: 0;
  color: #252525;
  font-family: sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}

.card__title .icon {
  flex: 1 0 10px;
  background: #007bff;
  color: #fff;
  padding: 10px 10px;
  transition: 200ms;
}

.card__title .icon > a {
  color: #fff;
}

.card__body {
  padding: 0 40px;
  display: flex;
  flex-flow: row no-wrap;
  margin-bottom: 25px;
}

.card__body > .half {
  box-sizing: border-box;
  padding: 0 15px;
  flex: 1 0 50%;
}

.card__body .featured_text h1 {
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  font-size: 64px;
  line-height: 50px;
  color: #181e28;
}

.card__body .featured_text p {
  margin: 0;
  padding: 0;
}

.card__body .featured_text p.sub {
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  text-transform: uppercase;
  color: #686e77;
  font-weight: 300;
  margin-bottom: 5px;
}

.card__body .featured_text p.price {
  font-family: "Fjalla One", sans-serif;
  color: #252525;
  font-size: 26px;
}

.card__body .image {
  padding-top: 15px;
  width: 100%;
}

.card__body .image img {
  display: block;
  max-width: 100%;
  height: auto;
}

.card__body .description {
  margin-bottom: 25px;
}

.card__body .description p {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  line-height: 27px;
  font-size: 16px;
  color: #555;
}


.card__footer {
  padding: 30px 40px;
  display: flex;
  flex-flow: row no-wrap;
  align-items: center;
  position: relative;
}

.card__footer::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 40px;
  width: calc(100% - 80px);
  height: 3px;
  background: #007bff;
  background: linear-gradient(to right, #007bff 0%, #007bff 70%, #ddd 70%, #ddd 100%);
}

.card__footer .action button {
  cursor: pointer;
  border: 1px solid #007bff;
  padding: 14px 30px;
  color: #fff;
  background: #007bff;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  transition: 200ms;
}

.card__footer .action button:hover {
  background: #fff;
  color: darkblue;
}

@media screen and (max-width: 60.625rem) {
  body {
    min-width: 100%;
    min-height: 100%;
    margin: 0;
  }

  main {
    margin: 10px;
    margin-top: 20%;
  }

  .card__title {
    padding: 15px 20px;
  }

  .card__title h3 {
    text-align: left;
    font-size: 16px;
  }

  .card__title .icon {
    padding: 5px 5px;
  }

  .card__body {
    padding: 0 20px;
  }

  .card__body > .half {
    padding: 0 10px;
  }

  .card__body .featured_text h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .card__body .featured_text p.sub {
    font-size: 18px;
  }

  .card__body .featured_text p.price {
    font-size: 18px;
  }

  .card__body .description p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 3%;
  }

  .card__footer {
    padding: 15px 20px;
  }

  .card__footer::before {
    left: 20px;
    width: calc(100% - 40px);
  }

  .card__footer .action button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .card__body .image {
    padding-top: 15px;
    width: 30%;
  }

  .card__body .image img {
    display: block;
    max-width: 100%;
    height: auto;
  }

}

</style>