<template>
<div class="page-content">
  <div class="hero">
      <div class="container">
          <div class="grid-2-columns">
              <div>
                  <h1>Vaša klima, naša briga!</h1>
                  <h1>
                      <span class="blue">IGK Skrbin.</span>
                  </h1>
                  <div class="margin-30px">
                      <p class="subhead">Udobnost koja traje, klima koja diše!<br></p>
                  </div>
                  <div class="margin-60px">
                      <div class="button-flex">
                          <div class="circle-container">
                              <div class="circle">
                                <img src="../../src/assets/images/phone.png" alt="Your Image">
                              </div>
                              <p class="top-text">
                                  <span class="other-color">
                                      <b>Nazovite nas na: </b>
                                  </span>
                                  <b>+385 99 407 6827</b>
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
      <div class="layout grids">
          <div @click="emitKlima('hyundai')" id="hyundai" class="logo-main">
              <img alt="Hyundai" src="../../src/assets/images/hyundai.png" class="logo-image">
          </div>
          <div @click="emitKlima('fujitsu')" id="fujitsu" class="logo-main">
              <img alt="Fujitsu" src="../../src/assets/images/fujitsu.png" class="logo-image">
          </div>
          <div @click="emitKlima('toshiba')" id="toshiba" class="logo-main">
              <img alt="Toshiba" src="../../src/assets/images/toshiba.png" class="logo-image">
          </div>
          <div @click="emitKlima('mitsubishi')" id="mitsubishi" class="logo-main">
              <img alt="Mitsubishi" src="../../src/assets/images/mitsubishi.png" class="logo-image">
          </div>
           <div @click="emitKlima('aux')" id="aux" class="logo-main">
              <img alt="Aux" src="../../src/assets/images/aux.png" class="logo-image">
          </div>
           <div @click="emitKlima('qzen')" id="qzen" class="logo-main">
              <img alt="QZEN" src="../../src/assets/images/qzen.png" class="logo-image">
          </div>
      </div>
  </div>
</template>

<script>

export default
{
  name: 'HomePage',
  methods: {
    emitKlima(data)
    {
      this.$emit('klima', data)
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p {
  color: grey;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
}

ul {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 20px;
}

img {
  max-width: 100%;
  display: inline-block;
}

.top-text {
  padding-top: 2%;
}

.price {
  font-weight: bold;
  color: green;
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.circle {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #007bff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle img {
  max-width: 2rem;
  max-height: 2rem;
  object-fit: cover;
  border-radius: 50%;
  filter: invert(100%);
}

.layout {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.hero {
  background-image: url("../assets/images/aircondition.png"), url("https://assets-global.website-files.com/65082b8d382b2e778619271e/65086cd16aaf080e5d79ee34_stacked-waves-haikei%20(15).svg");
  background-position: 100%, 50% 90%;
  background-repeat: no-repeat, no-repeat;
  background-size: 670px, contain;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px 3% 250px;
  display: flex;
  position: relative;
  overflow: hidden;
  filter: drop-shadow(3px 0px 40px lightgray);
}

.subhead {
  font-size: 17px;
  font-weight: 500;
}

.page-content {
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
}

.container {
  z-index: 1;
  max-width: 1200px;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.margin-30px {
  margin-top: 30px;
}

.margin-60px {
  margin-top: 50px;
}

.dropdown {
  font-weight: 700;
}

.button-flex {
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  align-items: center;
  display: flex;
}

.other-color {
  padding-left: 10px;
  color: #007bff;
}

.grid-2-columns {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: .8fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.blue {
  color: #007bff;
}

.grids {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #fff;
  border-right: 1px solid #333438;
  grid-template-rows: auto;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
  align-items: start;
  position: relative;
}

.logo-main {
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 1px 1px #ddd, inset 1px 1px #ddd;
}

.logo-image {
  width: 100%;
  max-width: 140px;
  opacity: .65;
  filter: invert(20%) grayscale();
  object-fit: contain;
}

@media screen and (max-width: 991px) {
  .page-content {
    padding-left: 0;
    margin-top: 20%;
  }

  .hero {
    min-height: auto;
    background-size: 350px, contain;
    padding-top: 131px;
    padding-bottom: 90px;
  }

  .dropdown {
    width: 100%;
  }

  .button-flex {
    flex-direction: column;
    align-items: center;
  }

  .grid-2-columns {
    flex-direction: column;
    display: flex;
  }

  .logo-main {
    height: auto;
  }

  .logo-image {
    height: 100%;
    max-height: none;
    max-width: none;
  }
}

@media screen and (max-width: 767px) {
  .grid-2-columns {
    flex-direction: column;
  }

  .logo-main {
    height: auto;
  }

  .logo-image {
    padding: 10px;
    max-height: 40px;
    min-height: 40px;
    max-width: none;
  }

  .circle-container {
    flex-direction: row;
    align-items: center;
    height: 120px
  }

  .circle {
    margin-bottom: 10px;
  }

  .hero {
    background-position: 100% 82%, 50% 535px;
    background-size: 300px, contain;
    padding-bottom: 245px;
  }

  .logo-main {
    height: auto;
  }

  .layout {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }
  
  .grids {
    width: 100%;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #fff;
    border-right: 1px solid #333438;
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 1fr;
    align-items: start;
    position: relative;
  }
  
}

@media screen and (max-width: 479px) {

  .hero {
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat;
    background-size: auto;
    padding-top: 20px;
    padding-bottom: 19px;
  }

  .subhead {
    font-size: 18px;
  }

  .first-element {
    grid-row-gap: 0px;
  }

  .button-flex {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
    align-items: center;
  }

  .logo-main {
    height: auto;
    min-width: 70vw;
  }

  .logo-image {
    max-height: 20px;
    max-width: none;
  }
  
  .layout {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }
  
  .grids {
    width: 100%;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #fff;
    border-right: 1px solid #333438;
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 1fr;
    align-items: start;
    position: relative;
  }

  .top-text {
    padding-top: 0;
  }
}

</style>
