<template>
<div class="main">
  <div class="loader-container" v-if="loading">
    <span class="loader"></span>
  </div>
  <div class="contact-container">
    <h3>Kontaktirajte nas</h3>

    <div class="info-section">
      <h4>Radno vrijeme</h4>
      <p>Ponedeljak - Petak</p>
      <p>08:00 - 16:00</p>
    </div>

    <div class="info-section">
      <h4>Informacije za kontakt</h4>
      <p>E-mail: s.slavek@live.com</p>
      <p>Telefon: +385 99 407 6827</p>
    </div>

    <div class="question-container">
      <h2>Najčešća pitanja</h2>
      <div v-for="(qa, index) in faqs" :key="index" class="faq-item">
        <div class="question" @click="toggleAnswer(index)">
          {{ qa.question }}
          <i :class="['fas', qa.isOpen ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
        </div>
        <div v-if="qa.isOpen" class="answer">{{ qa.answer }}</div>
      </div>
    </div>

  </div>
</div>
</template>

<script>

export default
{
  name:'ContactPage',
  data()
  {
    return {
      name: '',
      email: '',
      title: '',
      message: '',
      loading: false,
      faqs: [
        { question: 'Kolika je cijena postavljanja klima uređaja?', answer: 'Cijena postavljanja klima uređaja ovisi o instalaciji, instalacije do 3m su otprilike 200€' },
        { question: 'Koliko traje postavljanje klima uređaja?', answer: 'Trajanje postavljanja klima uređaja ovisi o kompleksnosti instalacije, jednostavnija instalacija traje oko sat vremena.' },
        { question: 'Koliko dugo traje garancija klima uređaja?', answer: 'Garancija klima uređaja ovisi o uređaju.' },
      ],
    };
  },
  created()
  {
  },
  methods: {
    toggleAnswer(index) {
      this.faqs[index].isOpen = !this.faqs[index].isOpen;
    },
  }
};
</script>

<style scoped>
.faq-item {
  margin-bottom: 15px;
}

.question {
  cursor: pointer;
  padding: 10px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.answer {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}
.main {
  margin-top: 5.5%;
  z-index: 1;
}

.contact-container {
  padding: 4%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 50%;
  margin: 0 auto;
}

.info-section, .email-section {
  margin-bottom: 10px;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  width: 120px;
  height: 150px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-image: linear-gradient(#ddd 50%, #bbb 51%),
    linear-gradient(#ddd, #ddd), linear-gradient(#ddd, #ddd),
    radial-gradient(ellipse at center, #aaa 25%, #eee 26%, #eee 50%, #0000 55%),
    radial-gradient(ellipse at center, #aaa 25%, #eee 26%, #eee 50%, #0000 55%),
    radial-gradient(ellipse at center, #aaa 25%, #eee 26%, #eee 50%, #0000 55%);
  background-position: 0 20px, 45px 0, 8px 6px, 55px 3px, 75px 3px, 95px 3px;
  background-size: 100% 4px, 1px 23px, 30px 8px, 15px 15px, 15px 15px, 15px 15px;
  position: relative;
  border-radius: 6%;
  animation: shake 3s ease-in-out infinite;
  transform-origin: 60px 180px;
}

.loader:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 100%;
  width: 7px;
  height: 5px;
  background: #aaa;
  border-radius: 0 0 4px 4px;
  box-shadow: 102px 0 #aaa;
}

.loader:after {
  content: "";
  position: absolute;
  width: 95px;
  height: 95px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 20px;
  background-color: #bbdefb;
  background-image: 
    linear-gradient( to right, #0004 0%, #0004 49%, #0000 50%, #0000 100% ),
    linear-gradient(135deg, #64b5f6 50%, #607d8b 51%);
  background-size: 30px 100%, 90px 80px;
  border-radius: 50%;
  background-repeat: repeat, no-repeat;
  background-position: 0 0;
  box-sizing: border-box;
  border: 10px solid #DDD;
  box-shadow: 0 0 0 4px #999 inset, 0 0 6px 6px #0004 inset;
  animation: spin 3s ease-in-out infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(360deg) }
  75% { transform: rotate(750deg) }
  100% { transform: rotate(1800deg) }
}
@keyframes shake {
  65%, 80%, 88%, 96% { transform: rotate(0.5deg) }
  50%, 75%, 84%, 92% { transform: rotate(-0.5deg) }
  0%, 50%, 100%  { transform: rotate(0) }
}
form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-size: 12px
}

p {
  font-size: 12px
}

input,
textarea {
  margin-bottom: 5px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border: 1px solid #007bff;
}
.button:hover{
  background: #fff;
  color: darkblue;
}

@media screen and (max-width: 60.625rem) { 
  .contact-container {
    padding: 4%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    margin: 0 auto;
    margin-top: 4%;
    margin-bottom: 20%;
  }
  
  .main {
    margin-top: 20%;
  }

  .question-container {
    margin-bottom: 20%;
  }
}
</style>
