<template>
  <div id="app">
    <div class="header-navigation">
      <div class="wrapper">
        <div class="logo">
            <img src="@/assets/images/logo.png" alt="Logo image">
        </div>
        <ul class="navigation">
          <li><router-link @click="scrollToSection('home')">Početna</router-link></li>
          <li><router-link @click="scrollToSection('items')">Uređaji</router-link></li>
          <li><router-link @click="scrollToSection('contact')">Kontakt</router-link></li>
        </ul>
      </div>
    </div>

    <transition name="fade" v-if="!hideAllComponents">
      <section id="home">
        <Home @redirect="scrollToSection('contact')" @klima="changeKlima"></Home>
      </section>
    </transition>

    <transition name="fade" v-if="!hideAllComponents">
      <section id="items">
        <Items :selectedKlima=selectedKlima></Items>
      </section>
    </transition>

    <transition name="fade" v-if="!hideAllComponents">
      <section id="contact">
        <Contact></Contact>
      </section>
    </transition>

    <transition name="fade" v-if="!hideAllComponents">
      <section id="contact">
        <Footer></Footer>
      </section>
    </transition>

    <transition name="fade" v-if="hideAllComponents">
      <section id="product">
        <router-view></router-view>
      </section>
    </transition>
  </div>
</template>

<script>
import Footer from "./views/Footer.vue";
import Home from './views/Home.vue';
import Contact from './views/Contact.vue';
import Items from './views/Items.vue';

export default
{
  name: "App",
  components:
  {
    Footer,
    Home,
    Contact,
    Items,
  },
  data() {
    return {
      currentSection: 'home',
      hideAllComponents: false,
      selectedKlima: null,
    };
  },
  methods: {
    changeKlima(data)
    {
      this.selectedKlima = data
      this.scrollToSection('items')
    },
    scrollToSection(sectionId) {
      if (sectionId === 'home') {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else
    {
      this.currentSection = sectionId;
      this.$nextTick(() => {
        const targetSection = document.getElementById(sectionId);
        targetSection.scrollIntoView({ behavior: 'smooth' });
      });
    }
    }
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
#app {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  background-image: url("../src/assets/images/sowhite.png");
  height: 100vh;
  z-index: 1;
}

.header-navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: #242526;
}

li {
  list-style-type: none;
  color: white;
}

li:hover {
  list-style-type: none;
  color: lightgray;
}
.header-navigation .wrapper {
  position: relative;
  max-width: 81.25rem; 
  padding: 0;
  height: 4.375rem; 
  line-height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .logo img {
  max-width: 25%;
  max-height: 25%;
  margin-top: 5%;
}

.wrapper .navigation {
  display: flex;
  align-items: center;
}

.navigation ul {
  list-style: none;
  display: flex;
}

.navigation li {
  margin-right: 1rem;
}

.navigation a {
  color: #f2f2f2;
  text-decoration: none;
  font-size: 1rem; 
  font-weight: 500;
  padding: 0.5rem 1rem; 
  border-radius: 0.25rem; 
  transition: background 0.3s ease;
}

.navigation a:hover {
  background: #3A3B3C;
}

@media screen and (max-width: 60.625rem) { 

  .header-navigation .wrapper {
    line-height: 0;
  }

  .wrapper .logo img {
    padding-left: 10%;
    max-width: 100%;
    max-height: 100%;
  }

  .navigation a {
    padding: 0;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .wrapper .logo img {
    margin-left: 2%;
    margin-top: 1%;
    max-width: 30%;
    max-height: 30%;
  }
}

</style>